<template>
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Tests statistiques prévus</h2>
    </Container>
  </div>

  <div class="key-values key-values--footer-bottom">
    <KeyValue v-if="isShared" table label="Tests statistiques prévus"
      :value="protocol.tests_statistiques"
    />
    <KeyValue v-else-if="isBpe" table label="Tests statistiques prévus" type="input" required>
      <Input id="tests_statistiques" type="textarea"/>
    </KeyValue>
    <KeyValue v-else table label="Tests statistiques prévus" type="input">
      <Input id="tests_statistiques" type="textarea"/>
    </KeyValue>

  </div>
</template>

<script>
import KeyValue from '@/components/form/KeyValue.vue'
import Input from '@/components/form/Input.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'StatisticalTestsAddEditSubview',

  components: {
    KeyValue,
    Input,
    Container,
  },

  props: {
    pageTitle: {
      type: String,
    },
    validationSchema: {
      type: Array,
      default: undefined,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data(props) {
    const { validationSchema } = props
    const vs = validationSchema[0].concat(validationSchema[4])
    const { isSubmitting, errors } = this.formService.initFrom(
      vs,
    )
    return {
      protocol: {
        bpe: false,
      },
      form: {
        references: [],
        thematiques: [],
      },
      step: 6,
      routeName: this.$route.name,
      previousRouteName: null,
      nextRouteName: null,
      vs,
      isSubmitting,
      errors,
    }
  },

  emits: [
    'set-step',
    'populate',
    'set-route',
    'form-submitted',
    'set-submitted',
  ],

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.previousRouteName = from.name
    })
  },

  computed: {
    isBpe: {
      get() {
        return (this.protocol.bpe) ?? false
      },
      set(v) {
        this.protocol = v
        this.componentKey += 1
      },
    },
    isShared() {
      return (this.protocol.date_partage !== null && this.protocol.partage_accepte)
    },
  },

  watch: {
    modelValue(newVal) {
      // watch it
      if (newVal) {
        const { values } = this.formService
        const schema = this.protocolService.getSchema()
        const datas = {}

        Object.keys(this.vs.fields).forEach((node) => {
          if (node in values) {
            datas[node] = values[node]
          } else if (node in schema) {
            datas[node] = datas[node] ? datas[node] : schema[node]
          }
        })

        this.formService.setValues(datas)

        this.formService.validate(this.formService.values).then((errors) => {
          if (errors.valid) {
            this.$emit('form-submitted', this.formService.values)
          } else {
            this.formService.handleErrors(true)
            this.$emit('set-submitted')
          }
        })
      }
    },
  },

  async mounted() {
    this.emitter.emit('open-loader')
    const routeParams = {}

    this.form.references = await this.productService.getReferenceByUids(
      'tests_statistiques',
    )

    if (this.$route.params.id) {
      if (this.protocol.thematique) {
        this.ste = this.protocol.thematique
      }
      routeParams.id = this.$route.params.id
    }

    if (this.$route.name.includes('Edit')) {
      this.previousRouteName = 'protocolPhytosanitaryProductsEdit'
      this.nextRouteName = 'protocolRatingCalendarEdit'
    } else {
      this.previousRouteName = 'protocolPhytosanitaryProductsAdd'
      this.nextRouteName = 'protocolRatingCalendarAdd'
    }

    this.$emit('set-step', this.step)
    this.$emit('set-route', {
      routeName: this.routeName,
      previousRouteName: this.previousRouteName,
      nextRouteName: this.nextRouteName,
      routeParams,
    })
    this.protocol = await this.getProtocol()
    this.$emit('populate', this.protocol)
    this.emitter.emit('close-loader')
  },

  methods: {
    async getProtocol() {
      const response = await this.fetchService.get(`protocole/${this.$route.params.id}`)
      const protocol = await response.data
      this.isBpe = protocol

      return protocol
    },
  },
}
</script>

<style scoped>
</style>
